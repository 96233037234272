import React, { useState, useEffect } from "react";
import "../Styles/qrcode.css";
import Logo from "../Images/Qrcode_soft_logo.svg";
import GoogleStore from "../Images/google_play.svg";
import AppleStore from "../Images/apple_store.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const QrCode = () => {
  const text = "the Work Place For Service Providers.";
  const [currentText, setCurrentText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index <= text.length) {
      const timer = setTimeout(() => {
        setCurrentText(currentText + text[index]);
        setIndex((preIndex) => preIndex + 1);
      }, 400);

      return () => clearTimeout(timer);
    } else {
      setCurrentText("");
      setIndex(0);
    }
  }, [index, text, currentText]);

  useEffect(() => {
    if (navigator.userAgentData) {
      const platform = window.navigator.userAgentData.platform;
      if (platform === "Andriod") {
        window.location.replace(
          "https://play.google.com/store/apps/details?id=com.techservafrica.soft_purse"
        );
      }
    }
  }, []);
  return (
    <div className="qr-cont d-flex justify-content-center align-items-center container-fluid text-center">
      <div>
        <div className="qr-code-logo-cont">
          <img src={Logo} alt="logo" className="qrcode-logo" />
        </div>
        <div>
          <h2>
            <span className="">SOFT PURSE</span> {currentText}
          </h2>
          <p>With SoftPurse you can start your business with zero balance.</p>

          <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-center justify-content-md-center align-items-md-center gap-3">
            <div className="align-self-center align-self-sm-center align-self-md-start">
              <a href="https://play.google.com/store/apps/details?id=com.techservafrica.soft_purse">
                <LazyLoadImage src={GoogleStore} alt="Google store" />
              </a>
            </div>

            <div className="align-self-center align-self-sm-center align-self-md-start hero-download-btn">
              <a href="https://apps.apple.com/app/soft-purse/id6473126470">
                <LazyLoadImage src={AppleStore} alt="Apple store" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrCode;
