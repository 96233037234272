import React from "react";
import "../Styles/FAQ.css";

const FAQ = () => {
  const faqData = [
    {
      id: 1,
      question: "What is SOFT PURSE",
      answer:
        "SOFT PURSE is a Nigerian reliable service on-demand App, designed by Tech-Serv Technologies for connecting service providers to clients.",
    },
    {
      id: 2,
      question: "What do I need to register?",
      answer: "You will need a valid phone number.",
    },
    {
      id: 3,
      question: "How do I book for a Service Provider?",
      answer:
        "Click a search button to search for the service needed, and you will be connected to the best service provider closest to you.",
    },
    {
      id: 4,
      question: "How do I become a Service Provider on the app?",
      answer:
        "You will need to add details of the services you can render to an existing account.",
    },
    {
      id: 5,
      question: "Do I need to pay to book a service?",
      answer: "SoftPurse doesn't charge to connect you to a Service Provider.",
    },
    {
      id: 6,
      question: "How do I contact SOFT PURSE",
      answer: "Yes, you can send us an email at support@mysoftpurse.com.",
    },
  ];
  return (
    <div id="faq" className="pt-5 pb-5 bg-white">
      <div className="text-center mb-3">
        <p className="faq-heading">FREQUENTLY ASKED QUESTIONS</p>
        <div className="">
          <h2 className="question">Any Questions? We've got the answers.</h2>
        </div>
      </div>
      <div className="accordion d-flex flex-column gap-2" id="accordionPanelsStayOpenExample">
        {faqData.map((faq) => (
          <div className="accordion-item shadow-sm rounded-3" key={faq.id}>
            <h2 className="accordion-header">
              <button
                className="accordion-button h2 collapsed p-3 p-md-4"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#panelsStayOpen-collapse${faq.id}`}
                aria-expanded="true"
                aria-controls={`#panelsStayOpen-collapse${faq.id}`}
              >
                {faq.question}
              </button>
            </h2>
            <div
              id={`panelsStayOpen-collapse${faq.id}`}
              className="accordion-collapse collapse"
            >
              <div className="accordion-body">
                <p> {faq.answer}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
