import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TermsPage from "./Components/TermsPage";
import PrivacyPage from "./Components/PrivacyPage";
import RequestAccountDeletedPage from "./Components/RequestAccountDeletedPage";
import Index from "./Components/IndexPage";
import SupportPage from "./Components/SupportPage";
import ContactPage from "./Components/ContactPage";
import QrCode from "./Components/QrCode";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index homePage={true} />} />
        <Route path="/terms-of-service/" exact={true} element={<TermsPage />} />
        <Route path="/privacy-policy/" exact={true} element={<PrivacyPage />} />
        <Route
          path="/request-account-deleted/"
          exact={true}
          element={<RequestAccountDeletedPage />}
        />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/download" element={<QrCode />} />
      </Routes>
    </Router>
  );
}

export default App;
