import "./../Styles/getStarted.css";
import GoogleStore from "../Images/google_play.svg";
import AppleStore from "../Images/apple_store.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
const GetStart = () => {
  return (
    <div
      className="d-flex bg-white flex-column flex-md-row align-items-center justify-content-md-around container-fluid rounded-3 shadow-sm p-3 pt-5 pb-5 p-md-5 getStarted"
      id="how_it_works"
    >
      <div className="step-banner d-flex flex-column align-items-center align-items-md-start">
        <h2>Get Started in 3 Easy Steps</h2>
        <p className="text-center text-md-start">
          Here are 3 quick and easy steps to book a service provider on Soft
          Purse App.
        </p>
        <div className="step-mobile">
          <div className="d-flex gap-3">
            <span className="numerical">1</span>
            <div>
              <h4>Download and Sign Up</h4>
              <p>
                Sign up with just your phone number when you download the
                SoftPurse Mobile App from the App Store
              </p>
            </div>
          </div>
          <div className="d-flex gap-3">
            <span className="numerical">2</span>
            <div>
              <h4>Search for Services or Add Your Services</h4>
              <p>
                Users can explore a wide range of services available in your
                area, while service providers can showcase their services and
                set their availability
              </p>
            </div>
          </div>
          <div className="d-flex gap-3">
            <span className="numerical">3</span>
            <div>
              <h4>Book and Connect with Clients</h4>
              <p>
                Users can select their desired service, book it, and enjoy the
                convenience our app offers. Service providers can start
                receiving booking requests and grow their business with our app.
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-sm-row gap-2">
          {/* <div className="text-center "> */}
            <a href="https://play.google.com/store/apps/details?id=com.techservafrica.soft_purse" className="link">
              <LazyLoadImage src={GoogleStore} alt="Google store" />
            </a>
          {/* </div> */}

          {/* <div className="text-center "> */}
            <a href="https://apps.apple.com/app/soft-purse/id6473126470" className="link">
              <LazyLoadImage src={AppleStore} alt="Apple store" />
            </a>
          {/* </div> */}
        </div>
      </div>
      <div className="step">
        <div className="d-flex gap-3">
          <span className="numerical">1</span>
          <div>
            <h4>Download and Sign Up</h4>
            <p>
              Sign up with just your phone number when you download the
              SoftPurse Mobile App from the App Store
            </p>
          </div>
        </div>
        <div className="d-flex gap-3">
          <span className="numerical">2</span>
          <div>
            <h4>Search for Services or Add Your Services</h4>
            <p>
              Users can explore a wide range of services available in your area,
              while service providers can showcase their services and set their
              availability
            </p>
          </div>
        </div>
        <div className="d-flex gap-3">
          <span className="numerical">3</span>
          <div>
            <h4>Book and Connect with Clients</h4>
            <p>
              Users can select their desired service, book it, and enjoy the
              convenience our app offers. Service providers can start receiving
              booking requests and grow their business with our app.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStart;
