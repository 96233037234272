import { useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";
import "./../Styles/footer.css";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import privacyFile from "./../privacy_policy.md";

const Privacy = () => {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    axios
      .get(privacyFile)
      .then((res) => {
        setContent(res.data);
        setIsLoading((loading) => false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="container-fluid inner-container ps-3 pe-3 ps-md-5 pe-md-5 pt-4 pb-4 d-flex flex-column gap-4">
      {isLoading ? (
        <div className="d-flex justify-content-center loader">
          <PuffLoader color="#060606" size={100} />
        </div>
      ) : (
        <ReactMarkdown children={content} />
      )}
    </div>
  );
};
export default Privacy;
