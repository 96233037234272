import Footer from "./Footer"
import Nav from "./NavBar"
import Terms from "./Terms"

const TermsPage = ()=> {
  return (
    <div>
      <Nav show={false} />
      <Terms />
      <Footer />
    </div>
  )
}
export default TermsPage