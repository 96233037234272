import React from "react";
import "../Styles/Navbar.css";
import logo from "../Images/Logo.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-md navbar-light fixed-top bg-transparent " >
      <div className="container-fluid Max-width-cont">
        <div className="logo-container">
          <a className="navbar-brand" href="/">
            <LazyLoadImage
              src={logo}
              alt="Logo"
              width="195.38"
              height="60"
              className="logo-image"
            />
          </a>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav text-center text-md-start d-flex flex-md-row flex-column">
            <li className="nav-item">
              <a
                className="nav-link active"
                aria-current="page"
                href="/#header"
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#faq">
                FAQ
              </a>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/support">
                Support
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">
                Contact Us
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/download">
                Download
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
