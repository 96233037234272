import "../Styles/footer.css";
import logo from "../Images/footer_logo.svg";
import apple_store from "../Images/apple_store.svg";
import Facebook from "../Images/Facebook.svg";
import Gmail from "../Images/Gmail.svg";
import google_play from "../Images/google_play.svg";
import instagram from "../Images/Instagram.svg";
import twitter from "../Images/twitter.svg";
import whatsapp from "../Images/whatsapp.svg";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Footer = () => {
  return (
    <div
      className="container-fluid footer d-flex align-items-center justify-content-center text-white pt-5 pb-5"
      id="footer"
    >
      <div className="inner-container">
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-3 gap-md-0">
          <div className="d-flex flex-column gap-4">
            <a href="/">
              <LazyLoadImage
                src={logo}
                alt="SoftPurse Logo"
                className="footer_logo"
              />
            </a>
            <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start gap-3">
              <a
                className="text-decoration-none text-white fw-bold"
                href="/#header"
              >
                Home
              </a>
              <a
                className="text-decoration-none text-white fw-bold"
                href="/#how_it_works"
              >
                How it Works
              </a>
              <a
                className="text-decoration-none text-white fw-bold"
                href="/#get_in_touch"
              >
                Get in Touch
              </a>
            </div>
          </div>
          <div className="align-self-md-end">
            <h3 className="fs-5 text-center mb-3">Download the app</h3>
            <div className="d-flex flex-column flex-md-row gap-2 gap-md-3">
              <a
                href="https://play.google.com/store/apps/details?id=com.techservafrica.soft_purse"
                className="text-decoration-none"
              >
                <LazyLoadImage src={google_play} alt="Google Play Store" />
              </a>
              <a href="https://apps.apple.com/app/soft-purse/id6473126470">
                <LazyLoadImage src={apple_store} alt="Apple Play Store" />
              </a>
            </div>
          </div>
        </div>
        <div className="d-flex gap-3 mt-3 justify-content-center justify-content-md-start">
          <a href="https://web.facebook.com/profile.php?id=100077394650393">
            <LazyLoadImage src={Facebook} alt="Facebook" />
          </a>
          <a href="https://wa.me/+2348064991272">
            <LazyLoadImage src={whatsapp} alt="Whatsapp" />
          </a>
          <a href="https://www.instagram.com/techservafrica">
            <LazyLoadImage src={instagram} alt="Instagram" />
          </a>
          <a href="https://twitter.com/techservafrica">
            <LazyLoadImage src={twitter} alt="Twitter" />
          </a>
          <a href="mailto:support@mysoftpurse.com">
            <LazyLoadImage src={Gmail} alt="Gmail" />
          </a>
        </div>
        <hr />
        <div className="d-flex flex-column-reverse flex-md-row align-items-center align-items-md-start justify-content-md-between gap-3 gap-md-0">
          <p>Copyright SOFT PURSE {new Date().getFullYear()}</p>
          <a
            href="https://www.techservafrica.com"
            name="Tech-Serv Africa HomePage"
            className="text-white text-decoration-none"
          >
            Powered by Tech-Serv Technologies
          </a>
          <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start gap-3">
            {/* <Link
              className="text-decoration-none text-white"
              to="/request-account-deleted"
            >
              Delete My Account
            </Link> */}
            <Link
              className="text-decoration-none text-white"
              to="/terms-of-service"
            >
              Terms of Service
            </Link>
            <Link
              className="text-decoration-none text-white"
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
