import guarantee from "./../Images/guarantee.svg";
import service from "./../Images/service.svg";
import time from "./../Images/time.svg";
import boss from "./../Images/boss.svg";
import "./../Styles/why-choose-us.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ChooseUs = () => {
  return (
    <div className="d-flex gap-3 gap-md-5 flex-column  shadow-sm p-md-5 p-3 pt-5 pb-5 rounded-3 bg-white">
      <div className="d-flex flex-column flex-md-row justify-content-md-around align-items-center gap-4 gap-md-0 level">
        <div className="text-center text-md-start">
          <h3 className="">
            <LazyLoadImage src={time} alt="timer" /> Convenience and
            Accessibility
          </h3>
          <p className="mb-0">
            Easily access a variety of services, wherever you are, saving time
            for users and simplifying client acquisition for providers.
          </p>
        </div>
        <div className="text-center text-md-start">
          <h3 className="">
            <LazyLoadImage src={service} alt="service" />
            Time and Cost Savings
          </h3>
          <p className="mb-0">
            Users save time and money by quickly booking services, while
            providers reduce marketing costs and efficiently acquire clients.
          </p>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-md-around align-items-center gap-4 gap-md-0 level">
        <div className="text-center text-md-start">
          <h3 className="">
            <LazyLoadImage src={guarantee} alt="guarantee" />
            Quality and Trust
          </h3>
          <p className="mb-0">
            Users trust our vetted service providers, and providers build
            credibility, leading to more opportunities.
          </p>
        </div>

        <div className="text-center text-md-start">
          <h3 className="">
            <LazyLoadImage src={boss} alt="portfolio" /> Become your own Boss
          </h3>
          <p className="mb-0">
            Work only when you feel like;Your priorities and schedules matters
            only when you're in control.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChooseUs;
