import React from "react";
import "../Styles/Download.css";
import GoogleStore from "../Images/google_play.svg";
import AppleStore from "../Images/apple_store.svg";
import DownloadFrame from "../Images/Download_frame.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Download = () => {
  return (
    <div className="Download-container d-flex justify-content-center p-3 pt-5 pb-5 p-md-5">
      <div className="d-flex flex-column flex-md-row align-items-center m-0 m-md-5 gap-3">
        <div className="down-write text-center text-md-start">
          <h2 className="">Download our Mobile App</h2>
          <p className="">App is available on all app stores</p>
          <div className="d-flex gap-2 flex-column flex-md-row">
            <a
              href="https:play.google.com/store/apps/details?id=com.techservafrica.soft_purse"
              className=""
            >
              <LazyLoadImage src={GoogleStore} alt="Google play" />
            </a>
            <a
              href="https:apps.apple.com/app/soft-purse/id6473126470"
              className=""
            >
              <LazyLoadImage src={AppleStore} alt="Apple store" />
            </a>
          </div>
        </div>
        <div className="text-center">
          <LazyLoadImage
            className="download-frame"
            src={DownloadFrame}
            alt="App screenshot "
          />
        </div>
      </div>
    </div>
    // <div className="Download-container container-fluid p-5">
    //   <div className="">
    //     <div className="">
    //     <h1 className="text-center download-heading">
    //          Download our Mobile get App
    //        </h1>
    //        <p className="downloading-text">
    //          App is available on all app stores.
    //        </p>
    //        <div className="d-flex flex-column flex-md-row gap-2">
    //          <div className="col-sm-6">
    //            <a href="https:play.google.com/store/apps/details?id=com.techservafrica.soft_purse">
    //              <LazyLoadImage src={GoogleStore} alt="Google play" />
    //            </a>
    //          </div>
    //          <div className="col-sm-6 mb-2">
    //            <a href="https:apps.apple.com/app/soft-purse/id6473126470">
    //              <LazyLoadImage src={AppleStore} alt="Apple store" />
    //            </a>
    //          </div>
    //          </div>
    //     </div>
    //     <div className=""></div>
    //   </div>
    // </div>
    // <main className="Download-container container-fluid p-5">
    //   <div className="row justify-content-around d-flex ">
    //     <div className="col-12 col-sm-7 d-flex flex-column justify-content-center align-items-center ">
    //       <h1 className="text-center download-heading">
    //         Download our Mobile get App
    //       </h1>
    //       <p className="downloading-text">
    //         App is available on all app stores.
    //       </p>
    //       <div className="d-flex flex-column flex-md-row gap-2">
    //         <div className="col-sm-6">
    //           <a href="https://play.google.com/store/apps/details?id=com.techservafrica.soft_purse">
    //             <LazyLoadImage src={GoogleStore} alt="Google play" />
    //           </a>
    //         </div>
    //         <div className="col-sm-6 mb-2">
    //           <a href="https://apps.apple.com/app/soft-purse/id6473126470">
    //             <LazyLoadImage src={AppleStore} alt="Apple store" />
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //     <div className=" col-12 col-sm-5 justify-content-center">
    //       <div className=" d-flex justify-content-center justify-content-sm-start">
    //         <LazyLoadImage
    //           className="download-frame"
    //           src={DownloadFrame}
    //           alt="App screenshot "
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </main>
  );
};

export default Download;
