import Footer from "./Footer"
import Nav from "./NavBar"
import Privacy from "./Privacy"

const PrivacyPage = ()=> {
  return (
    <div>
      <Nav show={false} />
      <Privacy />
      <Footer />
    </div>
  )
}
export default PrivacyPage