import React from "react";
import "../Styles/Hero.css";
import "./../Styles/what_we_do.css";
import Hero_Bg from "../Images/Hero_Bg.svg";
import GoogleStore from "../Images/google_play.svg";
import AppleStore from "../Images/apple_store.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Hero = () => {
  return (
    <main
      className="container-fluid d-flex justify-content-center align-items-center mt-4"
      id="header"
    >
      <div className="innercontain gap-3 d-flex align-items-center hero">
        <div className="writeup_hero">
          <h1 className="hero-heading">
            The Work Place For <span>Service Providers</span>
          </h1>
          <p>With Soft purse, you can start your business with zero balance.</p>

          <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-center justify-content-md-start align-items-md-start gap-2">
            {/* <div className="align-self-center align-self-sm-center align-self-md-start"> */}
              <a href="https://play.google.com/store/apps/details?id=com.techservafrica.soft_purse" className="link">
                <LazyLoadImage src={GoogleStore} alt="Google store" />
              </a>
            {/* </div> */}

            {/* <div className="align-self-center align-self-sm-center align-self-md-start hero-download-btn"> */}
              <a href="https://apps.apple.com/app/soft-purse/id6473126470" className="link">
                <LazyLoadImage src={AppleStore} alt="Apple store" />
              </a>
            {/* </div> */}
          </div>
        </div>

        <div className="hero_image_div">
          <LazyLoadImage className="hero-image" src={Hero_Bg} alt="Hero Bg" />
        </div>
      </div>
    </main>
  );
};

export default Hero;
