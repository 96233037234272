import Footer from "./Footer";
import Nav from "./NavBar";
import RequestAccountDeleted from "./RequestAccountDeleted";

const RequestAccountDeletedPage = () => {
  return (
    <div className="fix-down">
      <Nav />
      <div className="form-delete">
      <RequestAccountDeleted />
      </div>
      <Footer />
    </div>
  );
};
export default RequestAccountDeletedPage;
