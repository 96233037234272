import React from "react";
import "../Styles/Support.css";
import Faq from "../Components/FAQ";
import NavBar from "./NavBar";
import Footer from "../Components/Footer";
import Download from "../Components/Download";
import location from "../Images/location.svg";
import phone from "../Images/supportbox.svg";
import info from "../Images/info.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SupportPage = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  return (
    <main className="support-cont bg-color">
      <NavBar />
      <div className="support_bg  ">
        <h1 className="text-center">Help and Support</h1>
      </div>
      <div className="Max-width-cont Max-cont-margin-top align-self-center container-fluid mb-5 d-flex flex-column .gap-5">
        <Faq />
        <Download />
      </div>
      <div className="text-center contact-sec Max-cont-margin-top">
        <p className="support-text ">Contact Us</p>
        <h2>Get in Contact with us</h2>
        <div className="flex d-flex justify-content-center gap-2">
          <LazyLoadImage className=" contact-btn" src={location} />
          <span className="text-black fs-5">
            No 36 Zik's Avenue, Uwani, Enugu, Enugu State
          </span>
        </div>
        <div className="flex d-flex justify-content-center ">
          <LazyLoadImage
            className="contact-btn"
            src={phone}
            placeholderSrc=""
          />
          <span className="text-black fs-5">+234 806 4991 272</span>
        </div>
        <a
          className="flex d-flex justify-content-center gap-2 mt-2"
          href="mailto:support@mysoftpurse.com"
        >
          <LazyLoadImage className="contact-btn" src={info} />
          <span className="align-self-center text-black fs-5">
            support@mysoftpurse.com
          </span>
        </a>
        <div className="flex d-flex justify-content-center  gap-2 mt-2">
          <LazyLoadImage className="contact-btn" src={info} />
          <span className="text-black fs-5">info@mysoftpurse.com</span>
        </div>
      </div>
      <div className="Max-cont-margin-top ">
        <Footer />
      </div>
    </main>
  );
};

export default SupportPage;
