// import { useState } from "react";
import WhatWeDo from "./What_we_do";
import business from "./../Images/business.svg";
import connect from "./../Images/connect.svg";
import multipleValue from "./../Images/multiple_value.svg";
import secureEnvironment from "./../Images/secure_environment.svg";

const WhatWeDoDisplay = () => {
  return (
    <>
      <WhatWeDo
        id={1}
        topic="Connect"
        heading={
          <>
            We Build Bridge between you and
            <span className="connect"> your needs</span>
          </>
        }
        body="Book for service providers close to you. Connect to customers you never reached"
        imgUrl={connect}
      />
      <WhatWeDo
        id={2}
        topic="Business"
        heading={
          <>
            We augment <span className="business">your skills</span> to create
            exceptional experience
          </>
        }
        imgUrl={business}
        body="Your skill is an asset that can be valued. Let’s help give you that value."
      />
      <WhatWeDo
        id={3}
        topic="Thrive"
        heading={
          <>
            We Create a <span className="secureEnvironment">secure & enabling environment</span> so that your
            services can thrive & your needs cared
          </>
        }
        imgUrl={secureEnvironment}
      />
      <WhatWeDo
        id={4}
        topic="SOFT PURSE"
        heading={
          <>
            <span className="multipleValueApp">One App</span> <span className="multipleValue">Multiple Values</span>
          </>
        }
        imgUrl={multipleValue}
        headingBody="We leave no stone unturned until your potential is unleashed"
      />
    </>
  );
};

export default WhatWeDoDisplay;
