import { Form, FloatingLabel } from "react-bootstrap";
import "./../Styles/footer.css";
import Swal from "sweetalert2";
const RequestAccountDeleted = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  function handleAccountExistence(e) {
    e.preventDefault();
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: "success",
      title: "Account has been deleted",
    });
  }
  return (
    <div className="container-fluid form-delete pt-5">
      <div className="container-fluid inner-container ps-3 pe-3 ps-md-5 pe-md-5 mt-md-5 mb-md-5 pt-5 pb-5 justify-content-center align-items-center align-self-center text-center shadow">
        <form action="" id="form" onSubmit={handleAccountExistence}>
          <FloatingLabel
            controlId="floatingInput"
            label="Phone Number"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="phoneNumber"
              placeholder="(000) - 000 - 0000"
            />
          </FloatingLabel>
          <button
            type="submit"
            className="ms-auto me-auto d-block mt-4 ps-4 pe-4 pt-2 pb-2 rounded-2 btn btn-success"
          >
            Request Account Deleted
          </button>
        </form>
      </div>
    </div>
  );
};

export default RequestAccountDeleted;
