import logo from "./../Images/soft_features_logo.svg";
import GoogleStore from '../Images/google_play.svg'
import AppleStore from '../Images/apple_store.svg'
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./../Styles/what_we_do.css";
const WhatWeDo = ({ id, topic, heading, body, imgUrl, headingBody }) => {
  return (
    <div
      className={`d-flex justify-content-around align-items-center p-3 gap-3 pt-5 pb-5 p-md-5 ${
        id % 2 ? "flex-md-row-reverse" : "flex-md-row"
      } ${id % 2 ? "flex-column-reverse" : "flex-column"}`}
    >
      <div className="imageSect">
        <LazyLoadImage src={imgUrl} alt={topic} />
      </div>
      <div
        className={`writeup gap-3 d-flex flex-column align-items-center align-items-md-start text-center text-md-start ${
          id % 2 ? "mb-3" : "mt-3"
        }`}
      >
        <div className="d-flex gap-3 ">
          <LazyLoadImage
            src={logo}
            alt="SoftPurse Logo"
            className="topic_logo"
          />
          <h4 className="topic mb-0">{topic}</h4>
        </div>
        <h2>{heading}</h2>
        {body && <p>{body}</p>}
        {headingBody && <p className="headingBody">{headingBody}</p>}

        <div className="d-flex flex-column flex-sm-row gap-2">
            {/* <div className="text-center "> */}
              <a href="https://play.google.com/store/apps/details?id=com.techservafrica.soft_purse" className="d-block link">
                <LazyLoadImage src={GoogleStore} alt="Google store" />
              </a>
            {/* </div> */}

            {/* <div className="text-center "> */}
              <a href="https://apps.apple.com/app/soft-purse/id6473126470" className="d-block link">
                <LazyLoadImage src={AppleStore} alt="Apple store" />
              </a>
            {/* </div> */}
          </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
