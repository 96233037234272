import NavBar from "./NavBar";
import Footer from "./Footer";
import ChooseUs from "./Why_Choose_Us";
import FAQ from "./FAQ";
import GetStart from "./GetStarted";
import WhatWeDoDisplay from "./Display_What_we_do";
import Download from "./Download";
import Hero from "./Hero";

function IndexPage() {
  return (
    <div className="App bg-color d-flex flex-column justify-content-center">
      <NavBar />
      <Hero />
      <div className="Max-width-cont Max-cont-margin-top align-self-center container-fluid mb-5 d-flex flex-column gap-4 .bg-color">
        <WhatWeDoDisplay />
        <ChooseUs />
        <GetStart />
        <div className="gap-0">
          <FAQ />
          <Download />
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default IndexPage;
